<template>
  <div class="nav-container">
    <div class="nav">
      <ul>
        <template v-for="( item, index ) of items">
          <li v-if="true" :key="'index-'+ item.txt">
            <a :href="'#idx'+ index">{{ item.txt }}</a>
          </li>
        </template>
        <li class="login-btn" v-if="!user" @click="handleLogin">登陆/注册</li>
        <li class="login-btn" v-if="user">{{ user }}</li>
      </ul>
    </div>
    <div class="login-container" v-if="showLogin">
      <div class="login-cont">
        <img class="login-bg" :src="lgbgimg" alt="">
        <form class="login-form" @submit.prevent="handleSign">
          <div class="login-title">欢迎回来！</div>
          <div class="login-form-info">
            <div class="input-wrap"><input type="text" placeholder="手机号" v-model="phone" name="phone"></div>
            <div class="input-wrap"><input type="password" placeholder="密码" v-model="psw" name="psw"></div>
            <div class="login-sw" @click="switchSign" v-text="loginType == 'login'?'去注册': '去登录'"></div>
            <div class="login-btn-wrap">
              <input name="btnModify" class="button" type="submit" id="btnModify" :value="loginType == 'login'?'登录': '注册'"/>
              <!-- <button v-show="loginType == 'login'" @click="handleSign">登录</button>
              <button v-show="loginType == 'reg'" @click="handleSign">注册</button> -->
              <!-- <button>注册</button> -->
            </div>
          </div>
        </form>
        <div class="form-close" @click="handlehideLogin">关闭</div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: "HeaderNavigation",
  components: { },
  data() {
    return {
      phone: '',
      psw: '',
      user: localStorage.getItem("phone")? '欢迎您！': undefined,
      loginType: 'login',
      showLogin: false,
      lgbgimg: require("@/assets/image/lg-bg.jpg"),
      items: [
        { txt: "主页", url: "/" },
        { txt: "服务优势", url: "/" },
        { txt: "海报展示", url: "/" },
        { txt: "关于我们", url: "/" },
        { txt: "行业资讯", url: "/" },
        { txt: "联系方式", url: "/" }
      ],
    };
  },
  methods: {
    switchSign() {
      this.loginType = this.loginType == 'login'? 'reg': 'login'
    },
    handleLogin(){
      this.showLogin = true
    },
    handleSign() {
      if (this.phone.length != 11) {
        alert('请输入正确的手机号码')
        return
      }
      if (this.psw.length < 5) {
        alert('请输入大于等于6位的密码')
        return
      }
      localStorage.setItem("phone", this.phone);
      localStorage.setItem("psw", this.psw);
      this.user = '欢迎您！'
      this.handlehideLogin()
    },
    handlehideLogin(){
      this.showLogin = false
    },
    goAnchor(id) {
      console.log(id)
      // if (id ===  )
      let anchor = document.getElementById(id)
      let distance = 0;
      let total = anchor.offsetTop
      let step = total/ 30
      jump()
      function jump() {
        if(distance < total) {
          distance += step
          document.documentElement.scrollTop = distance
          document.body.scrollTop = distance;
          window.pageYOffset = distance
          setTimeout(jump, 10)
        } else {
          document.documentElement.scrollTop = total
          document.body.scrollTop = total
          window.pageYOffset = total
        }
      }
    }
  }
};
</script>

<style>
.nav {
  /* width: 1024px; */
  position: absolute;
  right: 20%;
  top: 2em;
  z-index: 1;
}
.nav > ul {
  display: flex;
  list-style: none;
  margin: 0;
  justify-content: space-around;
}
.nav > ul li {
  flex-grow: 1;
  padding: 15px 12px;
  text-align: center;
  position: relative;
}
.nav > ul li:hover {
  background-color: rgba(0, 0, 0, .5);
  cursor: pointer;
}
.nav > ul li:hover > a {
  color: white;
}
.nav > ul li:hover .sec-nav {
  display: inline-block;
  z-index: 2;
}
.nav > ul li a {
  display: inline-block;
  width: 100%;
  font-size: 1.3em;
  color: #fff;
  text-decoration: none;
}
.login-btn {
  font-size: 1.3em;
  color: #fff;
}
.login-container{
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .5);
}
.login-cont{
  position: absolute;
  width: 800px;
  height: 486px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.login-bg{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.login-form .login-title{
  font-size: 16px;
  color: #558fbb;
  font-weight: bold;
  text-align: center;
}
.login-form{
  position: absolute;
  left: 60px;
  top: 80px;
  z-index: 2;
}
.login-form-info{
  display: flex;
  align-items: baseline;
  flex-flow: column;
  width: 242px;
  border-radius: 8px;
  color: #558fbb;
  text-align: left;
  box-shadow:  0 5px 10px #558fbb;
  margin-top: 12px;
  padding: 24px  24px 40px;
  box-sizing: border-box;
}
.input-wrap{
  display: flex;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid #defbfe;
  padding-bottom: 8px;
  margin-top: 10px;
}
.login-form input{
  flex: 1;
  height: 40px;
  line-height: 50px;
  background: transparent;
  color: #558fbb;
  font-size: 16px;
}
.form-close{
  position: absolute;
  right: 16px;
  top: 16px;
  font-size: 20px;
  color: #558fbb;
}
input::-webkit-input-placeholder{
  color: #558fbb;
  font-size: 14px;
}
.login-sw{
  width: 100%;
  font-size: 10px;
  color: #558fbb;
  text-align: right;
  margin-top: 20px;
}
.login-btn-wrap {
  width: 100%;
  margin: 24px 0 0;
}
.login-btn-wrap .button{
  display: block;
  width: 160px;
  height: 40px;
  line-height: 40px;
  margin: 0 auto;
  border-radius: 20px;
  background: #103d80;
  color: #fff;
  border: 0;
}
</style>