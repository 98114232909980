<template>
  <div id="app">
    <HeaderNavigation />
    <PageBanner/>
  </div>
</template>

<script>
import PageBanner from "./components/PageBanner.vue";
import HeaderNavigation from "./components/HeaderNavigation.vue";

export default {
  name: 'App',
  components: {
    HeaderNavigation,
    PageBanner
  },
  metaInfo: {
    title: "探险家赋能科技",
    meta: [
      { name: "keywords", content: "探险家赋能科技 一键转发   精准获客  分享有礼   营销增长" },
      { name: "description", content: "1000位网红口腔医生都在使用的朋友圈运营神器" },
    ],
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
