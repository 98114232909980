<template>
  <div class="page-banner">
    <div  v-for="(item, index) in images" :key="index">
      <a v-if="item.url" :href="item.url" :target="item.b? item.b:'_self'" :style="'cursor:'+item.cursor" >
        <img :id="'idx'+index" :src="item.val" alt="" />
      </a>
      <img v-else-if="item.evt" :id="'idx'+index" :src="item.val" alt="" @click="payDialog = true"/>
      <img v-else :id="'idx'+index" :src="item.val" alt=""/>
    </div>
    <div class="dialog-cont" v-show="selDialog">
      <div class="dialog-cont-wrap">
        <p>支持储值卡和信用卡，需要开通网银</p>
        <div class="bank-card">
          <div v-for="(item, index) in bank" :key="index"><input type="radio" name="color" id="color" value="red"/><img :src="item" alt=""></div>
        </div>
        <div class="input-bank">
          <p>银行卡</p>
          <p>
            <input type="text" v-model="buyValue" />
            <span>价格：2元</span>
          </p>
          <p class="btn-wrap"><button @click="selDialog = false">关闭</button><button @click="showAlert">下一步</button></p>
        </div>
      </div>
    </div>
    <div class="pay-code-wrap-bg" v-show="payDialog">
      <div class="pay-code-wrap">
        <h3 style="text-align: right; line-height: 40px;font-size: 18px"><span @click="payDialog = false">关闭</span></h3>
        <p>有偿信息 付费阅读<span style="color: red;">2元/条</span></p>
        <img :src="code" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageBanner",
  data() {
    return {
      selDialog: false,
      payDialog: false,
      buyValue: 2,
      code: require("@/assets/image/pay.png"),
      bank: [
        require("@/assets/image/bank/0.jpg"),
        require("@/assets/image/bank/1.jpg"),
        require("@/assets/image/bank/2.jpg"),
        require("@/assets/image/bank/3.jpg"),
        require("@/assets/image/bank/4.jpg"),
        require("@/assets/image/bank/5.jpg"),
        require("@/assets/image/bank/6.jpg"),
        require("@/assets/image/bank/7.jpg"),
      ],
      images: [
      { val: require("@/assets/image/p1.jpg"), cursor: 'default', evt: ''},
      { val: require("@/assets/image/p2.jpg"), cursor: 'default', evt: ''},
      { val: require("@/assets/image/p3.jpg"), cursor: 'default', evt: ''},
      { val: require("@/assets/image/p4.jpg"), cursor: 'default', evt: ''},
      { val: require("@/assets/image/p5.jpg"), cursor: '', evt: 'tobuy'},
      { val: require("@/assets/image/p6.jpg"), cursor: 'default', evt: ''},
      {url: 'https://beian.miit.gov.cn/', val: require("@/assets/image/p7.jpg"), b: '_blank', evt: ''}]
    };
  },
  methods: {
    tobuy(){
      this.selDialog = true
      console.log('pppp')
    },
    showAlert() {
      var con = confirm("此功能需要付费");  
      console.log(con)
      this.payDialog = con

    }
  }
};
</script>
<style>
.page-banner {
  /* width: 1024px; */
  margin: 0 auto;
  /* width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative; */
}
.page-banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.dialog-cont{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .6);
  z-index: 2;
}
.dialog-cont-wrap{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 830px;
  background: #fff;
  padding: 50px 100px;
}
.bank-card{
  display: flex;
  flex-wrap: wrap;
}
.bank-card div{
  display: flex;
  align-items: center;
}
.bank-card img {
  width: 200px;
  object-fit: none;
}
.input-bank{
  text-align: left;
  margin-top: 60px;
  /* display: flex;
  align-items: center; */
}
.input-bank p{
  padding-block: 10px;
}
.input-bank input{
  width: 300px;
  text-indent: 8px;
  background: #ededed;
  line-height: 30px;
  margin-right: 10px;
}
.input-bank button{
  display: block;
  width: 160px;
  height: 30px;
  margin: 0 auto;
}
.input-bank  .btn-wrap{
  display: flex;
  width: 500px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}
.pay-code-wrap{
  background: #fff;
  position: fixed;
  left: 50%;
  top: 50%;
  width: 260px;
  height: 300px;
  z-index: 4;
  transform: translate(-50% ,-50%);
  padding: 0 50px;
  font-size: 16px;
}
.pay-code-wrap img{
  width: 200px;
  height: 200px;
  margin: auto;
}
.pay-code-wrap-bg{
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: rgba(0, 0, 0, .6);
}

</style>